import { createAction } from '@reduxjs/toolkit';

type Method = 'get' | 'post' | 'put' | 'delete';

interface ApiCallData {
  id?: string;
  url: string;
  method?: Method;
  data?: object;
  onStart?: string;
  onSuccess?: string;
  onError?: string;
  bypassThrottle?: boolean;
  onBackgroundTaskFinished?: string;
  onBackgroundTaskFailed?: () => any;
}

// api action creators
export const apiCallBegan = createAction<ApiCallData>('api/callBegan');
export const apiCallSuccess = createAction<ApiCallData>('api/callSuccess');
export const apiCallFailed = createAction<ApiCallData>('api/callFailed');
