import { combineReducers } from 'redux';
import providersReducer from './entities/providers';
import servicesReducer from './entities/services';
import servicesProvidersReducer from './entities/serviceProviders';
import servicesOriginsReducer from './entities/serviceOrigins';
import servicesDomainsReducer from './entities/serviceDomains';
import purgesReducer from './entities/purges';
import certificatesReducer from './entities/certificates';
import behaviorsReducer from './entities/behaviors';
import behaviorsNewReducer from './entities/behaviors_new';
import asyncTasksReducer from './entities/asyncTasks';
import trafficPoliciesReducer from './entities/trafficPolicies';
import logsReducer from './entities/logs';
import computeReducer from './entities/compute';
import wafReducer from './entities/waf';
import availabilityMonitoring from './entities/availabilityMonitoring';
import userManagement from './entities/userManagement';
import tokenManagement from './entities/tokenManagement';
import auditLogs from './entities/auditLogs';
import alerts from './entities/alerts';
import communicationChannels from './entities/communicationChannels';
import alertHistory from './entities/alertHistory';
import billing from './entities/billing';
import supportTicket from './entities/supportTicket';
import securityMonitoring from './entities/securityMonitoring';
import performanceMonitoring from './entities/performanceMonitoring';
import importServices from './entities/importServices';
import accessLogs from './entities/accessLogs';
import serviceTraffic from './entities/serviceTraffic';
import serviceTraffic2 from './entities/serviceTraffic2';
import originSets from './entities/originSets';
import loadBalancers from './entities/loadBalancers';
import realTimeLogs from './entities/realTimeLogs';
import serviceTotalTraffic from './entities/serviceTotalTraffic';
import topStats from './entities/topStats';
import mainStats from './entities/mainStats';
import accountStats from './entities/accountStats';
import geoStats from './entities/geoStats';
import geoFencing from './entities/geoFencing';
import urlSigningKeys from './entities/urlSigningKeys';
import userStats from './entities/userStats';

export default combineReducers({
  providers: providersReducer,
  services: servicesReducer,
  serviceProviders: servicesProvidersReducer,
  serviceOrigins: servicesOriginsReducer,
  serviceDomains: servicesDomainsReducer,
  purges: purgesReducer,
  certificates: certificatesReducer,
  behaviors: behaviorsReducer,
  behaviorsNew: behaviorsNewReducer,
  asyncTasks: asyncTasksReducer,
  trafficPolicies: trafficPoliciesReducer,
  logs: logsReducer,
  compute: computeReducer,
  waf: wafReducer,
  availabilityMonitoring: availabilityMonitoring,
  userManagement: userManagement,
  tokenManagement: tokenManagement,
  auditLogs: auditLogs,
  alerts: alerts,
  communicationChannels: communicationChannels,
  alertHistory: alertHistory,
  billing: billing,
  supportTicket: supportTicket,
  securityMonitoring: securityMonitoring,
  performanceMonitoring: performanceMonitoring,
  importServices: importServices,
  accessLogs: accessLogs,
  serviceTraffic: serviceTraffic,
  originSets: originSets,
  loadBalancers: loadBalancers,
  serviceTraffic2: serviceTraffic2,
  realTimeLogs: realTimeLogs,
  serviceTotalTraffic: serviceTotalTraffic,
  topStats: topStats,
  mainStats: mainStats,
  accountStats: accountStats,
  geoStats: geoStats,
  geoFencing: geoFencing,
  urlSigningKeys: urlSigningKeys,
  userStats: userStats,
});
