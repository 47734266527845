export const textDict = {
  requestLogsTitle: 'Request Logs',
  addRuleButtonTitle: 'Add Rule',
  addDestinationButtonTitle: 'Add Destination',
  ruleNameColumn: 'Name',
  ruleDestinationColumn: 'Destination',
  rulePathPatternColumn: 'Path Pattern',
  ruleStageColumn: 'Stage',
  destinationNameColumn: 'Name',
  destinationTypeColumn: 'Type',
  destinationBucketNameColumn: 'Bucket Name',
  tableNoData: 'No Data',

  // Common elements
  // Buttons
  iButtonApply: 'APPLY', // {textDict.iButtonApply}
  iButtonApplyLowercase: 'Apply', // {textDict.iButtonApply}
  iButtonApplyChanges: 'Apply Changes', // {textDict.iButtonApplyChanges}
  iButtonCancel: 'CANCEL', // {textDict.iButtonCancel}
  iButtonCreate: 'CREATE', // {textDict.iButtonCreate}
  iButtonClearFilters: 'CLEAR FILTERS', // {textDict.iButtonClearFilters}
  iButtonSearch: 'SEARCH', // {textDict.iButtonSearch}
  iButtonNext: 'NEXT', // {textDict.iButtonNext}
  iButtonNextSymbol: 'NEXT >',
  iButtonClose: 'CLOSE',
  iButtonFinishNormal: 'Finish',
  iButtonSaveChanges: 'SAVE CHANGES', // {textDict.iButtonSaveChanges}
  iButtonNew: 'New', // {textDict.iButtonNew}
  iButtonDeployAll: 'DEPLOY ALL', // {textDict.iButtonDeployAll}
  iButtonVerify: 'VERIFY',
  iButtonLogin: 'LOGIN',
  iButtonApprove: 'APPROVE',
  iButtonLoginNormal: 'Login',
  iButtonSearchNormal: 'Search',
  iButtonImportCertificate: 'Import Certificate',
  iButtonSubmit: 'Submit',
  iButtonDone: 'Done',

  //Options
  yes: 'Yes',
  no: 'No',
  onCap: 'ON',
  offCap: 'OFF',

  //Errors
  validationErrorFieldRequired: 'This field is required',
  incorrectConfirmationText: 'Incorrect confirmation text',
  invalidURL: 'Invalid URL',
  invalidEmail: 'Invalid email',

  //words
  search: 'Search',
  action: 'Action',
  status: 'Status',
  domains: 'Domains',
  origins: 'Origins',
  average: 'Average',
  all: 'All',
  delete: 'Delete',
  confirm: 'Confirm',
  deny: 'Deny',
  options: 'Options',
  password: 'Password',
  passwords: 'Passwords',
  email: 'Email',
  type: 'Type',
  update: 'Update',
  unknown: 'Unknown',
  close: 'Close',
  copied: 'Copied',
  new: 'New',
  other: 'Other',
  failed: 'Failed',
  activeNonCap: 'active',

  cloudFlare: 'CloudFlare',
  cloudFront: 'CloudFront',
  akamai: 'Akamai',
  highwinds: 'Highwinds',

  noData: 'No Data',
  firstName: 'First Name',
  lastName: 'Last Name',
  companyName: 'Company Name',
  phoneNumber: 'Phone Number',
  edgeCompute: 'Edge Compute',
  //uppercase

  //import { textDict } from '../../utils/text-utils';
  // import { textDict } from '../../../utils/text-utils';

  //_nav.js
  //  TOP BAR
  navHome: 'Home',
  navServices: 'Services',
  navCDNProviders: 'CDN Providers',
  navTLSCertificates: 'TLS Certificates',
  //_settingsNav
  settingsNavAccountSettingsHeader: 'Account Settings',
  settingsNavAccountUserManagement: 'User Management',
  settingsNavAccountTokens: 'Account Tokens',
  settingsNavAccountSSO: 'Single Sign-On',
  settingsNavAccountAuditLog: 'Audit Log',
  settingsNavAccountAlerts: 'Alerts',
  settingsNavAccountAlertsSettings: 'Settings',
  settingsNavAccountAlertsHistory: 'History',
  settingsNavAccountBilling: 'Billing',
  settingsNavUserPreferencesHeader: 'User Preferences',
  settingsNavUserPreferencesBasicInfo: 'Basic Info',
  settingsNavUserPreferences2FA: '2-FA Setup',
  settingsNavServicesDashboard: 'Dashboard',
  settingsNavServicesCDNProviders: 'CDN Providers',
  settingsNavServicesTrafficSteering: 'Traffic Steering',
  settingsNavServicesTrafficPolicies: 'Traffic Policies',
  settingsNavServicesGeoFencing: 'Geo Fencing',
  settingsNavServicesDomains: 'Domains',
  settingsNavServicesOrigins: 'Origins',
  settingsNavServicesBehaviors: 'Behaviors',
  settingsNavServicesMonitors: 'Monitors',
  settingsNavServicesAvailability: 'Availability',
  settingsNavServicesPerformance: 'Performance',
  settingsNavServicesCacheManagement: 'Cache Management',
  settingsNavServicesSecurity: 'Security',
  settingsNavServicesWAF: 'WAF',
  settingsNavServicesRateLimiting: 'Rate Limiting',
  settingsNavServicesMonitoring: 'Monitoring',
  settingsNavServicesEvents: 'Events',
  settingsNavServicesTrafficControl: 'Traffic Control',
  settingsNavServicesOriginSets: 'Origin Sets',
  settingsNavServicesLoadBalancers: 'Load Balancers',
  settingsNavServicesEdgeCompute: 'Edge Compute',
  settingsNavServicesLogs: 'Logs',
  signedURLs: 'Signed URLs',

  //Components
  //DateRangePicker
  drpLastHour: 'Last hour',
  drpLastDay: 'Last day',
  drpLast7Days: 'Last 7 days',
  drpLast14Days: 'Last 14 days',
  drpLast30Days: 'Last 30 days',
  drpLast3Months: 'Last 3 months',
  drpLastYear: 'Last Year',
  drpSetDateButton: 'SET DATE',
  //DeleteConfirmation
  dcTitle: 'Confirmation',
  dcToConfirm: 'to confirm',
  dcNotice: 'Notice',
  //GraphPeriodControl
  HOUR: 'HOUR',
  DAY: 'DAY',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
  //GraphPeriodControl
  fieldIsRequired: 'This field is required',
  //Pagination
  paginationPrevious: 'Previous',
  paginationNext: 'Next',
  //Progress
  progressTotalPercentage: 'Total Percentage',
  //AsyncTasks
  asyncTasksYears: ' years',
  asyncTasksMonths: ' months',
  asyncTasksDays: ' days',
  asyncTasksHours: ' hours',
  asyncTasksMinutes: ' minutes',
  asyncTasksSeconds: ' seconds',
  asyncTasksAgo: ' ago',
  asyncTasksCToastHeader: 'Tasks',
  //AppHeader
  vendorsTooltipTitleSynced: 'Synced',
  vendorsTooltipTextEdgePlatformsInSync: 'Edge platforms are in sync',
  vendorsTooltipTitleDeploying: 'Deploying',
  vendorsTooltipTextEdgePlatformsSyncing: 'Edge platforms are syncing',
  vendorsTooltipTitleError: 'Error',
  vendorsTooltipTextEdgePlatformsError: 'Edge platforms error',
  //AppHeaderDropdown
  ahdServices: 'Services',
  ahdProfile: 'Profile',
  ahdAccount: 'Account',
  ahdImport: 'Import',
  ahdContactSupport: 'Contact Support',
  ahdLogout: 'Logout',

  //Onboarding tips
  onboardingTipsIgnore: 'Ignore',
  onboardingTipsCreateCertificateTitle: 'Tip: Your next step is to create a TLS certificate',
  onboardingTipsCreateCertificateButton: 'Create certificate',
  onboardingTipsCertificateDomainVerificationTitle:
    'Tip: Add CNAME record for certificate domain verification.',
  onboardingTipsCertificateDomainVerificationButton1: 'Copy name',
  onboardingTipsCertificateDomainVerificationButton2: 'Copy value',
  onboardingTipsCertificateDomainVerificationButtonTooltip1: 'copy CNAME record name to clipboard',
  onboardingTipsCertificateDomainVerificationButtonTooltip2: 'copy CNAME record value to clipboard',
  onboardingTipsCreateServiceTitle: 'Tip: Your next step is to create a new service',
  onboardingTipsCreateServiceButton: 'Create new service',
  onboardingTipsAddCdnProvidersTitle: 'Tip: Your next step is to add CDN providers to your service',
  onboardingTipsAddCdnProvidersButton: 'Add CDN providers',
  onboardingTipsDefineTrafficPolicyTitle:
    'Tip: Your next step is to create a default traffic policy for your new service',
  onboardingTipsDefineTrafficPolicyButton: 'Create new policy',
  onboardingTipsMoveTrafficTitle: 'Create a CNAME record with your DNS provider',
  onboardingTipsMoveTrafficSubtitle: "Point your domain to the service's CNAME",
  onboardingTipsMoveTrafficButton: 'Copy CNAME',
  onboardingTipsMoveTrafficButtonTooltip: 'Copy the service CNAME to clipboard',

  //Services
  newServiceTemplatesGeneric: 'Generic',
  newServiceTemplatesLiveStreaming: 'Live Streaming',
  newServiceTemplatesVODStreaming: 'VOD Streaming',
  newServiceAddButton: 'Add Service',
  newServiceAddTitle: 'Add New Service',
  newServiceName: 'Service Name',
  newServiceNameLabel: 'Name',
  newServiceNameValidationMessage:
    'Service name should be letters, numbers, underscores, hyphens and spaces',
  newServiceNameTextMuted: 'Name your service so it will be understood',
  newServiceDomain: 'Domain',
  newServiceDomainValidationMessage: 'Enter a valid domain name',
  newServiceDomainTextMuted: 'Domain of your website or application',
  newServiceOrigin: 'Origin',
  newServiceOriginValidationMessage: 'Enter a valid domain name or IP address',
  newServiceOriginTextMuted: 'At least one origin required to create a service',
  newServiceProviderTextMuted: 'Add CDN provider now or you can add them later',
  newServiceDescription: 'Description',
  newServiceSelectCertificate: 'Select Certificate',
  newServiceTLSCertificate: 'TLS Certificate',
  newServiceServiceTemplate: 'Service Template',
  serviceCardMetricsHits: 'Hits',
  serviceCardMetricsBytes: 'Bytes',
  serviceCardMetricsErrorRate: 'Error Rate',
  serviceCardMetricsLastHour: 'Last Hour',
  serviceMenuDeleteMessagePart1: 'This service uses paid CloudFlare website. ',
  serviceMenuDeleteMessagePart2:
    'Choose if you want to disconnect it from IORiver or delete completely on CloudFlare',
  serviceMenuDeleteMessageNoCloudflareWarning: 'Are you sure you want to delete this service?',
  serviceMenuEditServiceDescription: 'Edit Service Description',
  serviceMenuEditServiceSettings: 'Edit Service Settings',
  serviceMenuDeleteService: 'Delete Service',
  serviceMenuOpenService: 'Open Service',
  serviceMenuCopyCName: 'Copy CName',
  servicesTitle: 'Services',
  servicesImportButton: 'Import',
  servicesServiceName: 'Service Name',
  servicesHits: 'Hits',
  servicesBytes: 'Bytes',
  servicesErrorRate: 'Error Rate',
  servicesTrafficDistribution: 'Traffic Distribution',
  editServiceDescription: 'Description',
  editServiceSettingsTitle: 'Service Settings',
  certificateIsNotValidError: 'The certificate cannot be used until it becomes valid.',

  //Certificates
  certificatesTypeUserManaged: 'User Managed',
  certificatesTypeIORiver: 'IORiver',
  certificatesType3rdParty: '3rd Party',
  certificatesTypeDefault: 'Unknown',
  certificatesCreate: 'Create Certificate',
  certificatesDNSChallenges: 'DNS Challenges',
  certificatesDeleteConfirmation: 'Are you sure you want to delete this certificate?',
  certificatesExpiresOn: 'Expires On',
  certificatesManagedBy: 'Managed By',
  certificatesCN: 'CN',
  certificatePendingToolTip: `The certificate is not ready yet.
                              Please ensure that the CNAME records have been added according to the DNS challenges.
                              If this has already been done, it may take some time for the DNS configuration to fully propagate.`,

  //DNSChallenges
  DNSChallengesInstructions: 'Instructions',
  DNSChallengesSignIn: 'Sign in to your DNS provider, typically to register of your domain',
  DNSChallengesNavigateTo: 'Navigate to the section where DNS records are manged',
  DNSChallengesAddCNAME: 'Add the following CNAME records:',
  DNSChallengesCNAME: 'CNAME',
  DNSChallengesValue: 'Value',
  certificatesEditTitle: 'Edit Certificate',
  certificatesEditPrivateKey: 'Private Key',
  certificatesEditTypePrivateKey: 'Type Private Key Or Drop File',
  certificatesEditCertificate: 'Certificate',
  certificatesEdit: 'Type certificate or drop file',
  certificatesEditCertificateChain: 'Certificate Chain',
  certificatesEditTypeCaBundle: 'Type ca bundle or drop file',
  certificatesNewStepTitle1: 'Step 1: Name your certificate',
  certificatesNewStepTitle2: 'Step 2: Define your domains',
  certificatesNewVerifyOwnership: 'Verify ownership',
  certificatesNewCertificateSuccessfullyCreated:
    'The certificate was successfully created and is pending validation',
  certificatesNewAddRequiredDomainsBelow:
    'Add required domains below, you want to ascribe to the certificate we generate for you.  ',
  certificatesNewValidationError: "The domain you've entered is invalid",
  certificatesNewAlreadyAdded: 'Already added:',
  certificatesImportAddTitle: 'Add New Custom Certificate',
  certificatesImportCertName: 'Cert Name',

  //Login
  loginWelcome: 'Welcome back',
  loginPleaseEnter: 'Please enter your login details',
  loginEmail: 'Email',
  loginPassword: 'Password',
  loginAuthenticationCode: 'Authentication Code',
  loginDontHaveAccount: 'Dont have an account? For Sign Up please contact',
  //Page 404
  page404Oops: "Oops! You're lost.",
  page404Text: 'The page you are looking for was not found.',
  whatAreYouLookingFor: 'What are you looking for?',
  //Page 500
  page500Title: 'Houston, we have a problem!',
  page500Text: 'The page you are looking for is temporarily unavailable.',
  //Email verified
  emailVerified: 'Email verified',

  //Register
  registerThankYou: 'Thank you for registering',
  registerVerificationLink: "We've sent you email message with verification link",
  registerCreateNewAccount: 'Create New Account',
  registerEnterYourLoginDetails: 'Please enter your login details',
  registerCreatePassword: 'Create Password',
  registerConfirmPassword: 'Confirm Password',
  registerSIGNUPButton: 'SIGN UP',
  registerAlreadyHaveAccount: 'Already have an account?',
  registerLogIn: 'Log in',

  //Reset password
  resetPasswordCreate: 'Create New Password',
  resetPasswordSaveButton: 'SAVE NEW PASSWORD',

  //Request Account
  requestAccountThankYou: 'Thank you for registering',
  requestAccountWeWillGetInTouch: 'We will get in touch as soon as possible',
  requestAccountRegisterNewAccount: 'Register New Account',
  requestAccountPleaseFillRegistrationForm: 'Please fill the registration form',

  //importService
  importsTimeImportNewService: 'Import New Service',
  importsTimeColumn: 'Time',
  importsCDNProviderColumn: 'CDN Provider',
  importsServicesColumn: 'Services',
  importsCompletionColumn: 'Completion',
  //importServiceWizard - isw
  iswStep1: 'Select a CDN provider to import from it.',
  iswStep2: 'Select services to import',
  iswStep3: 'The import has been completed',
  iswImportNewServices: 'Import New Services',
  iswSelected: 'Selected',
  iswSelectProvider: 'Select Provider',
  reviewStepOrigins: 'Origins',
  reviewStepTotal: 'Total',
  reviewRateLimitRules: 'Rate Limit Rules',
  reviewStepDomains: 'Domains',
  reviewStepBehaviors: 'Behaviors',
  reviewStepFullyImported: 'Fully Imported',
  reviewStepFailed: 'Failed',

  //providers
  providersTitle: 'CDN Providers',
  providersAddProviderAccount: 'Add provider account',
  providersAddAccountName: 'Add an account name',
  providersAddProviderAccountTitle: 'Add CDN Provider',
  providersEditCredentials: 'Edit Credentials',
  providersEditCredentialsTitle: 'Edit CDN Account Credentials',
  providersEditCredentialsWarning:
    "Any incorrect changes to your credentials might affect your CDN's ability to function under IO River. Proceed with care and ensure accuracy.",
  providersEditSettingsTitle: 'Edit CDN Provider’s Settings',
  providersAddCommitment: 'Add commitment',
  providersEditAccountSettings: 'Edit Account Settings',
  providersDeleteConfirmationPart1: 'Are you sure you want to delete ',
  providersDeleteConfirmationPart2: ' provider?',
  providersEditTitlePart1: 'Edit ',
  providersEditTitlePart2: ' CDN Provider',
  providersAPIToken: 'API Token',
  providersAWSAccessKey: 'AWS Access Key',
  providersAWSAccessSecret: 'AWS Access Secret',
  providersSubscriptionId: 'Subscription Id',
  providersClientId: 'Client Id',
  providersTenantId: 'Tenant Id',
  providersClientSecret: 'Client Secret',
  providersAccessKey: 'Access Key',
  providersServiceAccountKey: 'Service Account Key',
  providersObjectId: 'Object Id',
  providersResourceGroupName: 'Resource Group Name',
  providersClientToken: 'Client Token',
  providersAccessToken: 'Access Token',
  providersBaseURL: 'Base URL',
  providersGroupName: 'Group Name',
  providersKey: 'Key',
  providersSecret: 'Secret',
  providersSubscriberID: 'Subscriber ID',
  providersAccessGroupID: 'Access Group ID',
  providersServiceComponentID: 'Service Component ID',
  providersAccountID: 'Account ID',
  providersAccountHash: 'Account Hash',
  providersStackID: 'Stack ID (Optional)',
  providersEditCommitmentTitlePart1: 'Edit ',
  providersEditCommitmentTitlePart2: ' Commitment',
  providersEditCommitmentMonthlyCommitment: 'Insert Monthly Commitment (in GB):',
  providersEditCommitmentValidationMessage: 'Enter valid number',
  providersAddSelectProvider: 'Select Provider',
  providersAddCDNProvider: 'CDN Provider',
  providersAddAPIToken: 'API Token',
  providersAddCredentials: 'Credentials',
  providersAddAssumeRole: 'Assume Role',
  providersAddRoleARN: 'Role ARN',
  providersAddExternalId: 'External Id',
  providersAddResourceGroupName: 'Resource Group Name',
  providersAddResourceKeyvaultName: 'Keyvault Name',
  providersAddOrganizationID: 'Organization ID',
  providersAddUserID: 'User ID',
  providersAddAccessKey: 'Access Key',
  providersAddSecretKey: 'Secret Key',
  providersAddContractID: 'Contract ID',
  providersAddInsertMonthlyCommitment: 'Insert Monthly Commitment (in GB):',
  addUnmanagedProvider: 'Add Unmanaged Provider',
  unmanaged: 'Unmanaged',
  // SERVICES - DASHBOARD
  dashboardTotalRequests: 'Total Requests', // {textDict.}
  dashboardTotalBytes: 'Total Bytes',
  dashboardCacheOffloadHits: 'Cache Offload Hits',
  dashboardCacheOffloadBytes: 'Cache Offload Bytes',
  dashboardPctErrors: 'Pct Errors',
  totalGraphsBytesPerMinute: 'Bytes Per Minute',
  totalGraphsStatusCodes: 'Status Codes',
  totalGraphsHTTPVersion: 'HTTP Version',

  // SERVICES - PROVIDERS
  providersCDNProviderColumn: 'CDN Provider',
  providersStatusColumn: 'Status',
  providersDeleteMessage:
    'Choose if you want to disconnect the provider from IORiver or delete completely on ',
  providersDeleteMessageConfirmation: 'Are you sure you want to delete this provider?',
  providersCDNproviderInactiveTooltip: 'Why you CDN provider is Inactive?',
  providersIncorrectConfirmationError: 'Incorrect confirmation text',
  providersSelectProvider: 'Select Provider', // {textDict.}
  providersSelectCDNProvider: 'Select CDN Provider',
  providersName: 'Name',
  providersNameYourAccount: 'Name your account',
  providersCName: 'CName',
  providersAkamaiMediaServicesStream: 'Akamai Media Services Stream',
  providersValidateRegex: '^[a-zA-Z0-9_\\-\\s]*$',
  providersValidationMessage:
    'Provider name should be letters, numbers, underscores, hyphens and spaces',

  // SERVICES - TRAFFIC POLICIES
  trafficPoliciesPolicyColumn: 'Policy', // {textDict.}
  trafficPoliciesGeoColumn: 'Geo',
  trafficPoliciesDistributionColumn: 'Distribution',
  trafficPoliciesTypeColumn: 'Type',
  trafficPoliciesFailoverColumn: 'Failover',
  trafficPoliciesAddSteps1: 'Choose CDN Provider',
  trafficPoliciesAddSteps2: 'Choose Traffic Switching Type',
  trafficPoliciesAddSteps3: 'Create New Policy',
  trafficPoliciesFinalStepHealthCheckOptions: 'Select Monitor',
  trafficPoliciesFinalStepPerformanceCheckOptions: 'Select Monitor',
  trafficPoliciesFinalStepAccordionHeaderDefineGeoLocations: 'Define Geo Locations',
  trafficPoliciesFinalStepAccordionBodySearchLocations: 'Search and select locations:',
  trafficPoliciesFinalStepAccordionHeaderSetPerformanceMonitor: 'Set Performance Monitor',
  trafficPoliciesFinalStepAccordionBodyAddPerformanceMonitor: 'Add Performance Monitor:',
  trafficPoliciesFinalStepAccordionHeaderEnableFailover: 'Enable Failover',
  trafficPoliciesFinalStepAccordionBodyAskEnableFailover: 'Would you like to enable failover?',
  trafficPoliciesFinalStepAddHealthMonitor: 'Add Health Monitor:',
  trafficPoliciesFinalStepAccordionHeaderSetTrafficWeights: 'Set Traffic Weights',
  trafficPoliciesFinalStepAccordionBodySetWeightsSplitting: 'Set weights for traffic splitting:',
  trafficPoliciesFinalStepAccordionPerformancePenalty: 'Performance Penalty',
  trafficPoliciesFinalStepAccordionHeaderDefinePrioritization: 'Define Prioritization',
  trafficPoliciesFinalStepAccordionBodyDefineCDNProviders:
    'Define the CDN providers with commitment to a certain amount of traffic andprioritize them. Then, perform the same action for the providers without commitment.',
  trafficPoliciesAddPerformanceCheck: 'Add Performance Check',
  trafficPoliciesPenalty: 'Penalty',
  trafficPoliciesWeight: 'Weight',
  trafficPoliciesNewCheckURL: 'URL',

  trafficPoliciesNoDefaultMessage:
    'You must define the default policy in order to start passing the traffic',
  trafficPoliciesTrafficPolicyToDeleteMessage:
    'Are you sure you want to delete the traffic policy for ',

  // geo fencing
  geoFencingTitle: 'Geo Blocking',
  geoFencingSubtitle:
    'Add block or allowed geo-location. Once you choose a method the other will stay disabled.',
  geoFencingConfigureGeos: 'Configure Geos',
  geoFencingConfigureGeosTitle: 'Add new Fencing',
  geoFencingConfigureGeosNoFencingPlaceholder:
    "You have still didn't add any Geo Fencing policies.",
  geoFencingCountry: 'Country',
  goeFencingCountries: 'Countries',
  geoFencingType: 'Type',
  geoFencingBlock: 'Block List',
  geoFencingAllow: 'Allow List',
  geoFencingConfirmCountriesBlocked: 'countries blocked',
  geoFencingConfirmCountriesAllowed: 'countries allowed',
  geoFencingConfirmText: 'Just to make sure you aware for the following changes.',
  geoFencingConfirmSummary: 'Short Summary',

  //traffic-switching-step
  tssStep1h1: 'Step 2 | Choose Traffic Switching Type',
  tssStep1h4: 'Select how traffic will be switched',
  tssDynamic: 'Dynamic',
  tssStatic: 'Static',
  tssCostBased: 'Cost Based',
  tssHint: 'Hint: Select at least one to continue',

  //provider-wizard-step
  pwsStep1h1: 'Step 1 | Choose CDN Provider', // {textDict.}
  pwsStep1h4: 'Select a single or multiple CDN vendors',
  pwsHint: 'Hint: Select at least one to continue',

  //wizard story
  wsTitle1: 'Basic Configuration',
  wsTitle2: 'Advanced Configuration',
  wsDescription1: 'The traffic will be split among the following CDN providers:',
  wsDescription2: 'Using following distribution type:',
  wsDescription3: 'In following geo locations:',
  wsDescription4: 'Performance Monitor Selected:',
  wsDescription5: 'Failover is:',
  wsDescription6: 'Health Monitor Selected:',
  wsDescription7: 'Traffic will be splitted by following weights:',
  wsApplyPolicyButton: 'Apply Policy',

  // SERVICES - DOMAINS
  domainsTitle: 'Domains', // {textDict.}
  domainsText: 'Create new domains and relate them to an origin, origin set or load balancer',
  domainsDomainsColumn: 'Domains',
  domainsPathsColumn: 'Paths',
  domainsDestinationColumn: 'Destination',
  domainsDeleteConfirmation: 'Are you sure you want to delete path mapping?',
  domainsAddDomainSelectDestination: 'Select Destination',
  domainsAddDomainCreateNewDomain: 'Create New Domain',
  domain: 'Domain',
  path: 'Path',
  destination: 'Destination',
  domainsAddPath: 'Add Path',
  domainsPath: 'Path',
  domainsSelectDestination: 'Select Destination',
  domainsHide: 'Hide',
  domainsShowAll: 'Show All (',

  // SERVICES - ORIGINS
  originsOriginsAddressColumn: 'Origins Address', // {textDict.}
  originsProtocolColumn: 'Protocol',
  originsPathColumn: 'Path',
  originsS3Column: 'S3 Origin',
  originsOriginShield: 'Origin Shield',
  originsDeleteConfirmation: 'Are you sure you want to delete ',
  originsAddCreateButton: 'Create',
  originsModalTitle: 'Set Origin',
  originsOriginAddress: 'Origin Address',
  originsValidationMessage: 'Origin host should be valid domain name or IP address',
  originsProtocol: 'Protocol',
  originsPath: 'Path',
  originsS3: 'S3 Bucket',
  originsPrivateS3: 'Private',
  originsS3Key: 'Key',
  originsS3Secret: 'Secret',
  originsS3Bucket: 'Bucket name',
  originsS3Region: 'Region',
  originsOriginShieldTitle: 'Set Origin Shield',
  originsUpdateS3CredentialsTitle: 'Update Private S3 Credentials',
  originsOriginShieldStepOne: 'Step 1: Select the CDN providers to enable Origin Shield',
  originsOriginShieldStepTwo: 'Step 2: Choose the Origin location',
  originsOriginShieldNoProviders:
    'To enable this feature, you need to set up at least one provider.',

  originsAddressHelperText: "Origin's domain name",
  originsPathHelperText: 'URL path to append to the origin domain name for origin requests',
  originIsS3: 'Origin is S3 bucket',
  originSubmitButton: 'Save changes',
  originRevealAdvanceSettings: 'Origin Advanced Settings',
  originAdvancedSettingsTitle: 'Advanced Setting',
  originHttpPort: 'Http port',
  originHttpsPort: 'Https port',
  originTimeoutSettings: 'Timeout (Millisecond)',
  originTimeoutDescription:
    'Number of milliseconds that to waits for a response from the origin (Not all CDNs support this feature).',
  originSNIHostNameTitle: 'SNI Hostname',
  originSNIHostNameDescription:
    'Use a custom value for the SNI hostname (Not all CDNs support this feature).',
  originVerifiedTLS: 'Verify TLS',
  originVerifiedTLSDescription: 'Verify Origin TLS Certificate',
  originPrivateBucketSettingsTitle: 'S3 Bucket Private Settings',
  originPrivateBucketCheckboxTitle: 'Private Bucket',
  originPrivateBucketCheckboxDescription: 'Bucket is Private',
  originUpdateCredentialsButton: 'Update credentials',

  // SERVICES - BEHAVIORS
  behaviorsNameColumn: 'Name', // {textDict.}
  behaviorsPathPattern: 'Path Pattern',
  behaviorsActions: 'Actions',
  behaviorsDeleteConfirmation: 'Are you sure you want to delete this behavior?',
  behaviorsEditAddNewBehaviorButton: 'Add New behavior',
  behaviorsEditBehavior: 'Edit Behavior',
  behaviorsEditName: 'Name',
  behaviorsEditNameValidationMessage:
    'Behavior name should be letters, numbers, underscores, hyphens and spaces',
  behaviorsEditPathPattern: 'Path Pattern',
  behaviorsEditAdditionalPaths: 'Additional Paths',
  behaviorsEditBehaviorActionsValidationMessage: 'Invalid actions',

  behaviorsActionsSelectOrigin: 'Select Origin',
  behaviorsActionsSelectDestination: 'Select Destination',
  // ba - behaviorsActions
  baNone: 'None', // {textDict.}
  baInclude: 'Include',
  baExclude: 'Exclude',
  baAll: 'All',
  ba1xx: '1xx',
  ba2xx: '2xx',
  ba3xx: '3xx',
  ba4xx: '4xx',
  ba5xx: '5xx',
  baActionType: 'Action Type',
  baCacheTTL: 'Cache TTL (Seconds)',
  baHeaderName: 'Header Name',
  baSource: 'Source',
  baDestination: 'Destination',
  baCORSHeaderName: 'CORS Header Name',
  baRedirectToURL: 'Redirect to URL',
  baStatusCode: 'Status Code',
  baViewerHeaderName: 'Viewer Header Name',
  baCookie: 'Cookie',
  baHeaderBehavior: 'Header Behavior',
  baHeaders: 'Headers',
  baQueryStringsBehavior: 'Query Strings Behavior',
  baQueryStrings: 'Query Strings',
  baCookiesBehavior: 'Cookies Behavior',
  baCookies: 'Cookies',
  baHostHeaderValue: 'Host Header Value',
  baHostHeaderUseOrigin: 'Use Origin Host',
  baOrigin: 'Origin',
  baLogDestination: 'Log Destination',
  baSampleRate: 'Sample Rate',
  baHeaderValue: 'Header Value',
  baAllowedMethods: 'Allowed Methods',
  baAllowedHeaders: 'Allowed Headers',
  baMaxAge: 'Max-Age',
  baEnabled: 'Enabled',
  baAddAction: 'Add Action',
  baOverride: 'Override',

  // SERVICES - MONITORING - MONITORS
  monitorsNameColumn: 'Name',
  monitorsTypeColumn: 'Type', // {textDict.}: 'Enter a valid URL',
  monitorsEditMonitorName: 'Monitor Name',
  monitorsEditURL: 'URL',
  monitorsEditURLValidationMessage: 'Enter a valid URL',
  monitorsDeleteConfirmation: 'Are you sure you want to delete this monitor?',

  // SERVICES - MONITORING - AVAILABILITY MONITORING
  amAvailabilityMonitor: 'Availability Monitor',
  amAvailabilityOvertime: 'Availability Overtime',
  amUptimeStatistics: 'Uptime statistics',
  amDowntimeByGeolocation: 'Downtime by geolocation',
  monitoringFilterByGeoLocations: 'Filter By Geo Locations',
  monitoringFilterByProviders: 'Filter By Providers',
  monitoringNoData: 'No Data',
  monitoringNoProvidersConfigured: 'No providers configured',
  // SERVICES - MONITORING - PERFORMANCE MONITORING
  pmPerformanceOvertime: 'Performance Overtime',
  pmAverageTime: 'Average time',
  pmMeasureBy: 'Measure By',
  pmMetric: 'Metric',
  pmTotal: 'Total',
  pmTCPConnect: 'TCP Connect',
  pmTLS: 'TLS',
  pmWait: 'Wait',

  // SERVICES - CACHE
  cachePurgeTimeColumn: 'Purge Time',
  cachePathColumn: 'Path',
  cacheStatusColumn: 'Status',
  purgePlaceholder: 'Enter path to purge',
  purgeValidationMessage: 'Purge path should be url path without scheme and without domain',
  purgeDeleteConfirmation: 'Are you sure you want to purge everything from CDN cache',
  purgeAllButton: 'Purge All',
  purgeButton: 'Purge',

  // SERVICES - SECURITY - WAF
  wafConfigurationTitle: 'WAF Configuration',
  wafConfigurationText:
    'Using the WAF configuration page, you can focus your security policy, create traffic custom rules, and use the industry standard managed rules.',
  wafCustomRules: 'Custom Rules',
  wafManagedRules: 'Managed Rules',
  wafCustomRulesHeader: 'Custom Rules Overview',
  wafCustomRulesText: 'Manage your custom rules or create a new custom rule.',
  //cl - condition line
  clOperatorsEquals: 'Equals',
  clOperatorsDoesntEqual: "Doesn't Equal",
  clOperatorsContains: 'Contains',
  clOperatorsDoesntContain: "Doesn't Contain",
  clOperatorsRegexMatch: 'Regex Match',
  clOperatorsRegexNoMatch: 'Regex No Match',
  clOperatorsStartsWith: 'Starts with',
  clOperatorsDoesntStartWith: "Doesn't start with",
  clOperatorsEndsWith: 'Ends with',
  clOperatorsDoesntEndWith: "Doesn't end with",
  clOperatorsContainsWord: 'Contains word',
  clOperatorsDoesntContainWord: "Doesn't contain word",
  clInList: 'In List',
  clNotInList: 'Not In List',
  clFieldDropdownLabel: 'Field',
  clFieldDropdownAvailableOptions: 'Select',
  clOperatorDropdownLabel: 'Field',
  clOperatorDropdownAvailableOptions: 'Select',
  clJSONField: 'JSON Field',
  clCookieName: 'Cookie Name',
  clParameterName: 'ParameterName',
  clValue: 'Value',
  clValuepPlaceholder: 'Comma separated list of IP addresses or CIDRs',

  //cr - custom rule
  crActionsLog: 'Log',
  crActionsBlock: 'Block',
  crActionsAllow: 'Allow',
  crActionsBypass: 'Bypass',
  crActionsAllActions: 'All Actions',
  crActionsBypassManagedRules: 'Bypass Managed Rules',
  crTimeUnitOptionsSeconds: 'Seconds',
  crTimeUnitOptionsMinutes: 'Minutes',
  crTimeUnitOptionsHours: 'Hours',
  crCreateRulePart1: 'Create ',
  crCreateRulePart2: ' Rule',
  crCreateCustomPart1: ' Create a custom ',
  crCreateCustomPart2:
    ' rule that matches the traffic characteristic. Any rule can be tested before it deployed.',
  crRuleName: 'Rule Name',
  crDefineConditionsTitle: 'Define Conditions',
  crDefineConditionsText: 'Define the list of conditions',
  crDefineConditionsValidationMessage: 'This field is required',
  crDefineCounterTitle: 'Define Counter',
  crDefineCounterText: 'Define when Rate-Limiting should take an action',
  crDefineCounterValidationMessage: 'Enter valid number of requests',
  crTimeWindow: 'Time Window',
  crTimeWindowValidationMessage: 'Enter valid duration',
  crDefineAction: 'Define Action',
  crChooseActionDropdown: 'Choose an action',
  crDuration: 'Duration',
  crDurationValidationMessage: 'Enter valid duration',
  //cro - custom rule overview
  croStatusColumn: 'Status',
  croNameColumn: 'Name',
  croActionColumn: 'Action',
  croSummaryColumn: 'Summary',
  croStatusAllStatuses: 'All Statuses',
  croStatusEnabled: 'Enabled',
  croStatusDisabled: 'Disabled',
  croTitle: 'Custom Rules Overview',
  croText: 'Manage your custom rules or create a new custom rule.',
  croSearch: 'Search',
  croAction: 'Action',
  croStatus: 'Status',
  croDiscord: 'DISCARD',
  croReorder: 'REORDER',
  croDeleteConfirmation: 'Are you sure you want to delete this custom rule?',
  //mrs - Managed Rule Sets
  mrsPL1: 'PL1',
  mrsPL2: 'PL2',
  mrsPL3: 'PL3',
  mrsPL4: 'PL4',
  mrsParanoiaLevel: 'Paranoia Level',
  mrsAnomalyThreshold: 'Anomaly threshold',
  mrsAnomalyThresholdError: 'Anomaly threshold should be positive integer',
  // mrsStatusColumn: 'Status', --using croStatusColumn instead
  // mrsNameColumn: 'Name',
  // mrsActionColumn: 'Action',
  mrsTitle: 'Managed Rule Sets',

  // SERVICES - SECURITY - RATE LIMITING
  rlStatusColumn: 'Status',
  rlNameColumn: 'Name',
  rlActionColumn: 'Action',
  rlSummaryColumn: 'Summary',

  filterByActionAllActions: 'All Actions',
  filterByActionBypass: 'Bypass',
  filterByActionBlock: 'Block',
  filterByActionAllow: 'Allow',
  filterByActionLog: 'Log',

  filterByStatusAllStatuses: 'All Statuses',
  filterByStatusEnabled: 'Enabled',
  filterByStatusDisabled: 'Disabled',

  // SERVICES - SECURITY - MONITORING
  smLabelsConfigLegit: 'Legit',
  smLabelsConfigMitigated: 'Mitigated',
  smLabelsConfigLogged: 'Logged',
  smRulesOptionsAllRules: 'All Rules',
  smRulesOptionsAllManagedRules: 'All Managed Rules',
  smRulesOptionsManagedRules: 'Managed Rules',
  smRulesOptionsWAFCustomRules: 'WAF Custom Rules',
  smRulesOptionsRateLimitRules: 'Rate Limit Rules',
  smTimestamp: 'Timestamp',
  smSourceIP: 'Source IP',
  smURI: 'URI',
  smMethod: 'Method',
  smAction: 'Action',
  smCountry: 'Country',
  smSecurityRules: 'Security Rules',
  smTotalRequests: 'Total N. of Requests',
  smTotalLegitRequests: 'Total N. of Legit Requests',
  smTotalMitigatedRequests: 'Total N. of Mitigated Requests',
  smTotalLoggedRequests: 'Total N. of Logged Requests',
  smSecurityMonitoring: 'Security Monitoring',
  smSampledRequestsTitle: 'Sampled requests',
  smSampledRequestsText: 'Samples of requests from the past 3 hours',
  // Security log expanded
  slMethod: 'Method:',
  slURL: 'URL:',
  slRules: 'Rules',
  slHeaders: 'Headers',
  slTableHeader: 'Header',
  slTableValue: 'Value',
  slTableRule: 'Rule',
  slCustomRules: 'Custom Rules',
  slManagedRules: 'Managed Rules',
  slTableAllowed: 'Allowed',
  slTableAnomalyScore: ' - Anomaly Score',
  slTableRateLimitRules: 'Rate Limit Rules',
  slTableAction: 'Action',

  // SERVICES - EVENTS (MONITORING)
  eventsTimestamp: 'Timestamp',
  eventsAction: 'Action',
  eventsCategory: 'Category',
  eventsSeverity: 'Severity',
  eventsAllEvents: 'All Events',
  eventsTrafficPolicy: 'Traffic Policy',
  eventsWAF: 'WAF',
  eventsEdgeComputing: 'Edge Computing',
  eventsAllActions: 'All Actions',
  eventsBlockTraffic: 'Block Traffic',
  eventsRouteTraffic: 'Route Traffic',
  eventsSeverityOptionsAllLevels: 'All Levels',
  eventsSeverityOptionsCritical: 'Critical',
  eventsSeverityOptionsError: 'Error',
  eventsSeverityOptionsWarning: 'Warning',
  eventsSeverityOptionsInfo: 'Info',

  // Log Expanded
  logExpandedWebApplicationFirewall: 'Web Application Firewall',
  logExpandedBlockTraffic: 'Block traffic',
  logExpandedLogDetails: 'Log Details',
  logExpandedCategory: 'Category:',
  logExpandedSeverity: 'Severity:',
  logExpandedAction: 'Action:',

  // SERVICES - TRAFFIC CONTROL - ORIGIN SETS
  osOriginSet: 'Origin Set',
  osAssociatedWith: 'Associated With',
  osName: 'Name',
  osOriginSetMembers: 'Origin Set Members',
  osProtocol: 'Protocol',
  osSetHostError: 'Enter a valid domain name or IP address',
  osPolicy: 'Policy',
  osRoundRobin: 'Round Robin',
  osDefineRoundRobinWeights: 'Define Round Robin Weights',
  osSplitEqually: 'Split Equally',
  osOriginSetMember: 'Origin Set Member',
  osWeight: 'Weight',

  // SERVICES - TRAFFIC CONTROL - LOAD BALANCERS
  loOptionsEditLoadBalancer: 'Edit Load Balancer',
  loOptionsEditCustomRules: 'Edit Custom Rules',
  loLoadBalancersColumn: 'Load Balancers',
  loAssociatedWithColumn: 'Associated With',
  loPolicyColumn: 'Policy',
  loCustomRulesColumn: 'Custom Rules',
  loManageCustomRulesButton: 'Manage Custom Rules',
  loDeleteConfirmation: 'Are you sure you want to delete ',
  loEditCustomRules: 'Load Balancer Custom Rules',
  loEditRouteTo: 'Route to: ',
  loEditConditions: ' Conditions',
  loEditCustomRuleName: 'Custom Rule Name',
  loEditValidationMessage: 'This field is required',
  loEditSelectOriginSet: 'Select Origin Set to Route to',
  loEditAddNewCustomRule: 'Add New Custom Rule',
  loEditAddNewLoadBalancer: 'Add New Load Balancer',
  loEditName: 'Name',
  loEditSelectOriginSets: 'Select Origin Sets',
  loEditPolicy: 'Policy',
  loRoundRobin: 'Round Robin',
  loDefineRoundRobinWeights: 'Define Round Robin Weights',
  loSplitEqually: 'Split Equally',

  // SERVICES - EDGE COMPUTE
  ecNameColumn: 'Name', // {textDict.}
  ecRoutesColumn: 'Routes',
  ecOnRequestColumn: 'On Request',
  ecOnResponseColumn: 'On Response',
  ecSearchByName: 'Search By Name',
  ecDeleteConfirmation: 'Are you sure you want to delete this edge function?',
  ecCreate: 'Create',
  ecUpdate: 'Update',
  ecNewComputeService: 'New Compute Service',
  ecApplyEdgeCompute: 'Apply Edge Compute',
  ecAddRoute: 'Add Route',
  ecOnRequest: 'On Request',
  ecOnResponse: 'On Response',
  ecOnRequestHandler: 'On Request Handler',
  ecOnResponseHandler: 'On Response Handler',

  // SERVICES - LOGS - ACCESS LOGS
  alDestinationColumnsName: 'Name',
  alDestinationColumnsType: 'Type',
  alDestinationColumnsBucketName: 'Bucket Name',
  alDestinationColumnsPath: 'Path',
  alDestinationColumnsAnonymizeIP: 'Anonymize IP',
  alTitle: 'Access Logs',
  alAddDestinationButton: 'Add Destination',
  alNoData: 'No Data',
  alDestinationDeleteConfirmation: 'Are you sure you want to delete this destination?',
  alLogRules: 'Log Rules',
  alDestinations: 'Destinations',
  alRuleDeleteConfirmation: 'Are you sure you want to delete this rule?',
  alEditRuleCreateNew: 'Create New',
  alEditRuleUpdate: 'Update',
  alEditRuleTitle: 'Rule',
  alEditRuleName: 'Rule Name',
  alEditRuleDestination: 'Destination',
  alEditRulePathPattern: 'Path Pattern',
  alEditRuleSamplingRate: 'Sampling Rate (%)',
  alEditRuleMethods: 'Methods',
  alEditRuleSelectMethods: 'Select methods you want to capture',
  alEditRuleRequestBody: 'Request Body',
  alEditRuleCaptureBodyRequestCheckbox: 'Check the box below',
  alEditRuleCaptureRequestBodyLabel: 'Capture Request Body',
  //Destination
  destinationCreateNew: 'Create New',
  destinationUpdate: 'Update',
  destinationTitle: 'Destination',
  destinationName: 'Destination Name',
  destinationType: 'Destination Type',
  destinationAWSS3Bucket: 'AWS S3 Bucket',
  destinationS3Compatible: 'S3 Compatible',
  destinationHydrolix: 'Hydrolix',
  destinationCredentials: 'Credentials',
  destinationAssumeRole: 'Assume Role',
  destinationAWSAccessKey: 'AWS Access Key',
  destinationAWSAccessSecret: 'AWS Access Secret',
  destinationRoleARN: 'Role ARN',
  destinationExternalID: 'External ID',
  destinationBucketName: 'Bucket Name',
  destinationPath: 'Path',
  destinationEndpoint: 'Endpoint',
  destinationRegion: 'Region',
  destinationHydrolixUser: 'User',
  destinationHydrolixPassword: 'Password',
  destinationHydrolixAuthenticationToken: 'Authentication token',
  destinationProject: 'Project',
  destinationTable: 'Table',

  // ACCOUNT SETTINGS - USER MANAGEMENT
  accountSettingsUserManagementTitle: 'User Management', // {textDict.}
  accountSettingsUserManagementInviteButton: 'INVITE',
  accountSettingsUserManagementEmailColumn: 'Email',
  accountSettingsUserManagementRoleColumn: 'Role',
  accountSettingsUserManagementStatusColumn: 'Status',
  accountSettingsUserManagement2FAColumn: '2-FA',

  accountSettingsUserManagementOptionsForUserDropdownDeleteUser: 'Delete User',
  accountSettingsUserManagementOptionsForUserDropdownReinvite: 'Reinvite',
  accountSettingsUserManagementOptionsForUserDropdownResetPassword: 'Reset Password',
  accountSettingsUserManagementOptionsForUserDropdownEditUser: 'Edit User',
  accountSettingsUserManagementOptionsForUserDropdownServicesRestriction: 'Services Restriction',
  accountSettingsUserManagementOptionClickedPasswordResetTitle: 'Password Reset',
  accountSettingsUserManagementOptionClickedPasswordResetMessagePart1:
    'By Approving this action we will send an email to ',
  accountSettingsUserManagementOptionClickedPasswordResetMessagePart2: ' to reset password',
  accountSettingsUserManagementOptionClickedPasswordReinviteTitle: 'Resend Invitation',
  accountSettingsUserManagementOptionClickedPasswordReinviteMessagePart1:
    'By Approving this action we will send an email to ',
  accountSettingsUserManagementOptionClickedPasswordReinviteMessagePart2: ' invitation',

  accountSettingsUserManagementRoleTextViewer: 'Viewer',
  accountSettingsUserManagementRoleTextEditor: 'Editor',
  accountSettingsUserManagementRoleTextAdmin: 'Admin',

  accountSettingsUserManagementIDropDownSelectedOptionDefault: 'Options',

  accountSettingsUserManagementDeleteConfirmation: 'Are you sure you want to delete this user?',
  accountSettingsUserManagementChangeRolePart1: 'Change ',
  accountSettingsUserManagementChangeRolePart2: ' permission',

  // ACCOUNT SETTINGS - USER MANAGEMENT - InviteUsers ( {textDict.} )
  accountSettingsInviteUsersPermissionsReadAccess: 'Read Access',
  accountSettingsInviteUsersPermissionsWriteAccess: 'Write Access',
  accountSettingsInviteUsersPermissionsEdit: 'Edit and Manage Users',

  accountSettingsInviteUsersTitle: 'User Invitations',
  accountSettingsInviteUsersEmail: 'Email',

  accountSettingsInviteUsersEmailColumn: 'Email',
  accountSettingsInviteUsersRoleColumn: 'Role',

  accountSettingsInviteUsersPermissionsTitle: 'Permissions',
  accountSettingsInviteUsersPermissionsDiv:
    'Invite new users and manage pending user invitations to your account.',
  accountSettingsInviteUsersEditorTitle: 'Editor',
  accountSettingsInviteUsersViewerTitle: 'Viewer',
  accountSettingsInviteUsersAdminTitle: 'Admin',

  // ACCOUNT SETTINGS - ACCOUNT TOKENS
  accountSettingsAccountTokensTitle: 'Token Management', //( {textDict.} )
  accountSettingsAccountTokensNameColumn: 'Name',
  accountSettingsAccountTokensCreatedByColumn: 'Created By',
  accountSettingsAccountTokensCreatedAtColumn: 'Created At',
  accountSettingsAccountTokensExpirationDateColumn: 'Expiration Date',
  accountSettingsAccountTokensRoleColumn: 'Role',
  accountSettingsAccountTokensGenerateButton: 'GENERATE TOKEN',

  accountSettingsAccountTokensDeleteConfirmation: 'Are you sure you want to delete this token?',
  accountSettingsAccountTokensChangeRolePart1: 'Change ',
  accountSettingsAccountTokensChangeRolePart2: ' permission',

  accountSettingsAccountTokensRoleOptionViewer: 'Read Only',
  accountSettingsAccountTokensRoleOptionPurge: 'Purge',
  accountSettingsAccountTokensRoleOptionAdmin: 'Full Access',

  // ACCOUNT SETTINGS - ACCOUNT TOKENS - CreateToken
  accountSettingsCreateTokenTitle: 'Create New Token',
  accountSettingsCreateTokenTokenName: 'Token Name',
  accountSettingsCreateTokenRole: 'Role',
  accountSettingsCreateTokenCopyYourToken: 'Copy Your Token',
  accountSettingsCreateTokenSaveWarning: 'Save your token. You will not be able to see it again',
  accountSettingsCreateTokenLabel: 'Token',
  accountSettingsCreateTokenExpirationDate: 'Expiration Date',
  accountSettingsCreateTokenNeverExpires: 'Never Expires',
  accountSettingsCreateTokenNoExpiration: 'No Expiration',

  // ACCOUNT SETTINGS - SINGLE SIGN-ON
  accountSettingsSingleSignOnSSOTitle: 'Set Up Single Sign-On', //( {textDict.} )
  accountSettingsSingleSignOnSSOTextPart1:
    'We support Single Sign-On (SSO) applications that use the Security Assertion Markup Language (SAML) protocol.',
  accountSettingsSingleSignOnSSOTextPart2:
    'This allows users to log in to multiple applications with one set of login credentials, increasing security and convenience',
  accountSettingsSingleSignOnInstruction:
    'Go to your IdP and use these settings to configure a new application for IO RIver',
  accountSettingsSingleSignOnAssertion: 'Assertion Consumer Service URI',
  accountSettingsSingleSignOnAudience: 'Audience URI(SP Entity ID)',
  accountSettingsSingleSignOnRecipient: 'Recipient',
  accountSettingsSingleSignOnName: 'Name ID Format',
  accountSettingsSingleSignOnMetadataTitle: 'Confirmation with Metadata',
  accountSettingsSingleSignOnMetadataText: 'Drag the XML IdP metadata file here to upload',
  accountSettingsSingleSignOnEnableSSOButton: 'ENABLE SSO',

  accountSettingsSingleSignOnSSOEnabledTitle: 'Enable Single Sign-On',
  accountSettingsForceSSO: 'Force SSO',
  accountSettingsSingleSignOnSSOEnabledTextPart1:
    'SSO is currently enabled. Disable Single sign-on to make changes to your SAML configuration.',
  accountSettingsSingleSignOnSSOEnabledTextPart2:
    'Disable this function will pause any option to log with SSO.',

  // ACCOUNT SETTINGS - AUDIT LOG
  auditLogType: 'Type',
  auditLogUser: 'User',
  auditLogScope: 'Scope',
  auditLogTimestampColumn: 'Timestamp',
  auditLogTypeColumn: 'Type',
  auditLogUserTokenColumn: 'User/Token',
  auditLogScopeColumn: 'Scope',
  auditLogMultiselect: ' selected',
  auditLogExpandedOccurredAt: 'Occurred At',
  auditLogExpandedType: 'Type',

  // ACCOUNT SETTINGS - ALERTS
  accountSettingsAlerts: 'Alerts',
  accountSettingsAlertsAddAlert: 'ADD ALERT +',
  accountSettingsAlertsOnOff: 'On/Off',
  accountSettingsAlertsName: 'Name',
  accountSettingsAlertsConditions: 'Conditions',
  accountSettingsAlertsThreshold: 'Threshold',
  accountSettingsAlertsCdnProviders: 'CDN Provides',
  accountSettingsAlertsHealthChecks: 'Health Checks',
  accountSettingsAlertsCommunication: 'Communication',
  accountSettingsAlertsServicesHint:
    'By selecting "All Services," you are including future services that will be added.',
  accountSettingsAlertsProvidersHint:
    'By selecting "All CDN Providers," you are including future provider that will be added.',
  accountSettingsAlertsHealthChecksHint:
    'By selecting "All Health Checks," you are including future health checks that will be added.',
  accountSettingsAlertsTopic: 'Topic',
  accountSettingsAlertsType: 'Type',
  accountSettingsAlertsChannels: 'Communication Channels',
  accountSettingsAlertsActions: 'Actions',
  accountSettingsAlertsAddChannel: 'ADD CHANNEL +',
  accountSettingsAlertsRecurringNotification: 'Recurring Notification',
  accountSettingsAlertsRecurringTimeValue: 'Time Value',
  accountSettingsAlertsRecurringTimeInterval: 'Time Interval',
  accountSettingsAlertsSetNewAlert: 'Set New Alert',
  accountSettingsAlertsDeleteConfirmation: 'Are you sure you want to delete this alert setting?',
  accountSettingsAlertsCommunicationChannelSetUp: 'Set Up Communication Channel',
  accountSettingsAlertsCommunicationChannel: 'Communication Channel',
  accountSettingsAlertsChannelWebhookUrl: 'Webhook URL',
  accountSettingsAlertsChannelWebhookUrlValidationMsg: 'Enter a valid webhook URL',
  accountSettingsAlertsChannelEmails: 'Emails',
  accountSettingsAlertsChannelEmailsHint:
    'Press Enter every time you want to add a new email address',
  accountSettingsAlertsChannelAdmin: 'Send alerts to all Admins.',
  accountSettingsAlertsChannelDeleteConfirmation:
    'Are you sure you want to delete this communication channel?',
  accountSettingsAlertsHistoryTimeRange: 'Time Range',
  accountSettingsAlertsHistoryAlertName: 'Alert name',
  accountSettingsAlertsHistoryAlertType: 'Alert types',
  accountSettingsAlertsHistoryAlertOccursAt: 'Occurs At',
  accountSettingsAlertsHistoryAlertProvider: 'Provider',
  accountSettingsAlertsHistoryAlertService: 'Service',
  accountSettingsAlertsHistoryAlertAlertMessage: 'Alert Message',
  accountSettingsAlertsHistoryAlertTestAlertTooltip: 'Sent a test alert message',
  accountSettingsAlertsHistoryAlertTestAlertSent: 'A test alert message has been sent.',

  // BILLING
  accountSettingsBilling: 'Billing',
  accountSettingsBillingMonth: 'Month',
  accountSettingsBillingDownloadPdf: 'DOWNLOAD PDF',
  accountSettingsBillingVendor: 'Vendor',
  accountSettingsBillingVendors: 'Vendors',
  accountSettingsBillingTraffic: 'Traffic (GB)',
  accountSettingsBillingUnitPrice: 'Unit Price',
  accountSettingsBillingPricingModel: 'Pricing Model',
  accountSettingsBillingTotal: 'Total',
  accountSettingsBillingMonthlyConsumption: 'Monthly Consumption',
  accountSettingsBillingCommitment: 'IO River Commitment',
  accountSettingsBillingThisMonth: 'This month',
  accountSettingsBillingLeft: 'Left',
  accountSettingsBillingBucketEndDate: 'Bucket end date',
  accountSettingsBillingNotice:
    'Notice! Charges for your current billing period are estimated charges.',
  accountSettingsBillingIoRiverServices: 'IO River Services',

  // CONTACT SUPPORT
  settingsNavAccountContactSupport: 'Contact Support',
  accountSettingsContactSupportHeaderText:
    "If you encounter a malfunction, a bug you'd like to report, difficulty navigating the system, or any unexpected system behavior, please reach out to us using the form below. We will respond within 24 hours of your submission.",
  accountSettingsContactSupportSuccessMsg: 'The form has been successfully completed and sent',
  accountSettingsContactSupportErrorMsg: 'Error message must be here',
  accountSettingsContactSupportSeverity: 'Severity',
  accountSettingsContactSupportCritical: 'Critical',
  accountSettingsContactSupportHigh: 'High',
  accountSettingsContactSupportMedium: 'Medium',
  accountSettingsContactSupportLow: 'Low',
  accountSettingsContactSupportTitle: 'Title',
  accountSettingsContactSupportDescription: 'Description',
  accountSettingsContactSupportThankYou: 'Thank you!',

  // USER PREFERENCES - BASIC INFO
  userPreferencesBasicInfoFormPageTitle: 'Basic Information',
  userPreferencesBasicInfoFirstName: 'First Name',
  userPreferencesBasicInfoLastName: 'Last Name',
  userPreferencesBasicInfoJobTitle: 'Job Title',
  userPreferencesBasicInfoPhone: 'Phone',
  userPreferencesBasicInfoEmail: 'Email',

  // USER PREFERENCES - 2-FA SETUP
  userPreferences2FASetupTitle: 'Two-Factor Authentication',
  userPreferences2FASetupText:
    'Setup a multi factor authentication using 3rd party apps (such as Google Authenticator).',
  userPreferences2FASetupInstructionsTitle: 'Instructions',
  userPreferences2FASetupInstructionsList1: 'Open your Multi Factor Authentication App',
  userPreferences2FASetupInstructionsList2: 'Scan the QR Code',
  userPreferences2FASetupInstructionsList3: 'Enter the code below',
  userPreferences2FASetupFormAuthenticationCode: 'Authentication Code',

  // Restrict Services
  restrictServicesOptionsLabel: 'Select Service',
  restrictServicesAccessRestrictionHeader: 'Access Restriction',
  restrictServicesAllServices: 'All Services',
  restrictServicesSpecificServices: 'Specific Services',
  cancelButtonText: 'Cancel',
  Save: 'Save',
  monitorsNewPerformanceToggleButton: 'Performance',
  monitorsNewAvailabilityToggleButton: 'Availability',
  monitorsNewMonitorName: 'Monitor Name',
  monitorsNewURL: 'URL',
  HTML: 'HTML',
  CSS: 'CSS',
  JS: 'JS',
  Origin: 'Origin',
  crDefineTimeWindow: 'Time Window',
  OnRequestHandler: 'On Request Handler',
  ipv4: 'IPv4',
  ipv6: 'IPv4 & IPv6',
  highLevel: 'Traffic Statistics',
  topStats: 'Analytics',
  geoStats: 'Geo statistics',
  cname: 'CNAME',
  projectId: 'GCP Project ID',
  providersServiceId: 'Service ID',
  providersDeleteConfirmationBuiltIn:
    'This is built-in account, if you remove it, adding it back can be done only via support. Are you sure?',
  builtInTooltip:
    'A built-in account is a CDN account provided by IO River with a monthly traffic limit of 1TB. You may delete this account if you prefer to use your own CDN accounts.',

  'http.request.domain': 'Hostname',
  'http.request.path': 'URL Path',
  'http.request.method': 'Request Method',
  'http.response.status_code': 'Response Status Code',
  'http.request.header': 'Request Header',
  'http.response.header': 'Response Header',
  'client.geo.country': 'Client Country',
  'client.device.is_mobile': 'Is Mobile Device',
  eq: 'Equals',
  ne: 'Not Equals',
  lt: 'Less Than',
  gt: 'Greater Than',
  le: 'Less Than Or Equals',
  ge: 'Greater Than Or Equals',
  in: 'In',
  not_in: 'Not In',
  match: 'Matches',
  not_match: "Doesn't Match",
  regex: 'Matches Regex',
  not_regex: "Doesn't Match Regex",
};

export function toUpperCase(str) {
  if (str.length === 0) {
    return '';
  }
  return str[0].toUpperCase() + str.slice(1);
}

export function getId() {
  return Math.random().toString(16).slice(2);
}

export function getPositiveIntegerValue(value) {
  if (typeof value === 'number') {
    return value;
  }
  if (value === '') {
    return 0;
  }
  const valueInt = parseInt(value);
  if (valueInt >= 0) {
    return valueInt;
  }
  return undefined;
}
