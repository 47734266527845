import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { Granularity } from '../../commonTypes';

interface UserStatRecord {
  timestamp: number;
  granularity: Granularity;
  unique_clients: number;
}

interface UserStatsState {
  userStatRecords: UserStatRecord[];
  error: string | null;
}

const initialState: UserStatsState = {
  userStatRecords: [],
  error: null,
};

const slice = createSlice({
  name: 'userStats',
  initialState,
  reducers: {
    // actions => action handlers

    usersStatsReceived: (userStats, action) => {
      userStats.userStatRecords = action.payload[0];
    },
    errorOccurred: (userManagement, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
      }

      userManagement.error = msg;
    },
  },
});

export const { usersStatsReceived, errorOccurred } = slice.actions;

export default slice.reducer;

// Action Creators

export const loadUsersStats = (serviceId: string, startTime: number, endTime: number) =>
  apiCallBegan({
    url: `/api/v1/user_stats/${serviceId}?startTime=${startTime}&endTime=${endTime}`,
    onSuccess: usersStatsReceived.type,
    onError: errorOccurred.type,
  });
