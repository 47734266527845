import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { colors, GraphColors } from '../model/trafficStatsModels';

export interface ProviderDetails {
  id: number;
  name: string;
}

export interface Provider {
  id: string;
  display_name: string;
  provider: number;
  account: string;
  provider_details: ProviderDetails;
  commitment_gb_per_month: number;
  enable_commitment: boolean;
  service_uids: string[];
  credentials: string;
  is_vcdn: boolean;
  is_builtin: boolean;
}

type Colors = { [key: string]: GraphColors };

interface ProvidersState {
  list: Provider[];
  creating: boolean;
  updatingCredentials: boolean;
  updatingSettings: boolean;
  loading: boolean;
  lastFetch: number | null;
  error: string | null;
  colors: Colors;
}

const initialState: ProvidersState = {
  list: [],
  creating: false,
  updatingCredentials: false,
  updatingSettings: false,
  loading: true,
  lastFetch: null,
  error: null,
  colors: {},
};

const resetState = (state: ProvidersState) => {
  state.error = null;
  state.loading = false;
  state.creating = false;
  state.updatingCredentials = false;
  state.updatingSettings = false;
};

const slice = createSlice({
  name: 'accountProviders',
  initialState,
  reducers: {
    // actions => action handlers

    accountProvidersReceived: (accountProviders, action) => {
      accountProviders.list = action.payload[0];
      accountProviders.loading = false;
      accountProviders.lastFetch = Date.now();
      let count = 0;
      let providerNamesSet = new Set<string>();
      for (let accountProvider of accountProviders.list) {
        providerNamesSet.add(accountProvider.provider_details.name);
      }
      let providerNames = Array.from(providerNamesSet);
      for (let accountProvider of providerNames) {
        accountProviders.colors[accountProvider.toLowerCase()] = colors[count % 5];
        count++;
      }
      accountProviders.colors['other'] = colors[count % 5];
    },

    accountProviderAdded: (accountProviders, action) => {
      accountProviders.list.push(action.payload[0]);
      resetState(accountProviders);
    },

    accountProviderUpdated: (accountProviders, action) => {
      accountProviders.list = accountProviders.list.map((content) =>
        content.id === action.payload[0].id ? action.payload[0] : content,
      );
      resetState(accountProviders);
    },

    accountProviderDeleted: (accountProviders, action) => {
      const deleted = action.payload[1].payload.id;
      accountProviders.list = accountProviders.list.filter((p) => p.id !== deleted);
      resetState(accountProviders);
    },

    onError: (accountProviders, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
      }

      accountProviders.error = msg;
      accountProviders.loading = false;
    },

    onCreateStart: (accountProviders) => {
      accountProviders.error = null;
      accountProviders.loading = true;
    },

    showCreating: (accountProviders, action) => {
      accountProviders.creating = action.payload;
      accountProviders.error = null;
    },
    showUpdatingCredentials: (accountProviders, action) => {
      accountProviders.updatingCredentials = action.payload;
      accountProviders.error = null;
    },
    showUpdatingSettings: (accountProviders, action) => {
      accountProviders.updatingSettings = action.payload;
      accountProviders.error = null;
    },
  },
});

export const {
  accountProvidersReceived,
  accountProviderDeleted,
  accountProviderAdded,
  accountProviderUpdated,
  onError,
  onCreateStart,
  showCreating,
  showUpdatingCredentials,
  showUpdatingSettings,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadAccountProviders = (bypass = false) =>
  apiCallBegan({
    url: '/api/v1/account-providers/',
    onSuccess: accountProvidersReceived.type,
    onError: onError.type,
    bypassThrottle: bypass,
  });

export const deleteAccountProvider = (p: string) =>
  apiCallBegan({
    id: p,
    url: `/api/v1/account-providers/${p}/`,
    method: 'delete',
    onSuccess: accountProviderDeleted.type,
    onError: onError.type,
    onBackgroundTaskFailed: () => loadAccountProviders(true),
  });

export const updateAccountProvider = (id: string, p: Provider) =>
  apiCallBegan({
    url: `/api/v1/account-providers/${id}/`,
    method: 'put',
    onSuccess: accountProviderUpdated.type,
    data: p,
    onError: onError.type,
  });

export const addAccountProvider = (p: Provider) =>
  apiCallBegan({
    url: `/api/v1/account-providers/`,
    method: 'post',
    onSuccess: accountProviderAdded.type,
    data: p,
    onError: onError.type,
    onStart: onCreateStart.type,
    onBackgroundTaskFailed: () => loadAccountProviders(true),
  });
